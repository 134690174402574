import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Textarea from 'react-expanding-textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout/';

export const query = graphql`
    query ContactPageQuery {
        site {
            siteMetadata {
                recaptcha {
                    sitekey
                }
            }
        }
    }
`;

export default class ContactPage extends React.Component {

    constructor (props) {
        super(props);
        this.submit          = this.submit.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.state = {
            succeeded: false,
            failed:    false,
            alert:     null,
            fieldErrors: {
                name:    false,
                email:   false,
                message: false,
            },
            name: '',
            email: '',
            message: '',
            captcha: '',
        };
    }

    submit (e) {
        e.preventDefault();
        const { name, email, message, captcha } = this.state;
        axios({
            method: 'post',
            url: '/api/contact/',
            data: {
                name, email, message, captcha
            },
        }).then((res) => {
            this.setState(res.data);
        });
    }

    fieldChange (field) {
        return (e) => {
            this.setState({
                [field]: e.currentTarget.value,
            });
        }
    }

    onCaptchaChange (value) {
        this.setState({
            captcha: value,
        });
    }

    getFieldClass (name) {
        return this.state.fieldErrors[name] ? 'error' : '';
    }

    getAlertClass () {
        if (this.state.succeeded) return 'form-alert form-alert-valid';
        if (this.state.failed)    return 'form-alert form-alert-error';
        return 'form-alert';
    }

    getAlertIcon () {
        if (this.state.succeeded) return <FontAwesomeIcon icon={faCheckCircle} className='form-alert-icon' />;
        if (this.state.failed)    return <FontAwesomeIcon icon={faTimesCircle} className='form-alert-icon' />;
        return null;
    }

    getAlert () {
        return this.state.alert ? (
            <div className={this.getAlertClass()}>
                { this.getAlertIcon() }
                { this.state.alert }
            </div>
        ) : null;
    }

    render () {
        return (
            <StaticQuery query={query} render={(props) => (
                <Layout title='Contact Me'>
                    <article>

                        <header>
                            <h1>Contact Me</h1>
                        </header>

                        <section>

                            <form name='contact' onSubmit={this.submit}>

                                { this.getAlert() }

                                <div className='form-group'>
                                    <label htmlFor='name'>Your Name</label>
                                    <input name='name' type='text' value={this.state.name} className={this.getFieldClass('name')} onChange={this.fieldChange('name')} />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='email'>Your Email</label>
                                    <input name='email' type='email' value={this.state.email} className={this.getFieldClass('email')} onChange={this.fieldChange('email')} />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='message'>Message</label>
                                    <Textarea name='message' value={this.state.message} className={this.getFieldClass('message')} onChange={this.fieldChange('message')} rows='3' />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='captcha'>Captcha</label>
                                    <ReCAPTCHA sitekey={props.site.siteMetadata.recaptcha.sitekey} onChange={this.onCaptchaChange} />
                                    <input name='captcha' type="hidden" value={this.state.captcha} />
                                </div>

                                <div className='form-group'>
                                    <div className='button-container'>
                                        <button type='submit'>
                                            Send Message
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </section>

                    </article>
                </Layout>
            )} />
        );
    }
}
